import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../contexts/Contexts";
import { MultiSelect } from '@mantine/core';

function ProcessesPage() {

    const [processes, setProcesses] = useState([]);
    const user = useContext(UserContext);

    const [states, setStates] = useState([]);

    function isStateFiltered(value) {
        if (states.length === 0) return true; 
    
        return states.some(loaded => loaded.toLowerCase() == value.toLowerCase());
    }

    const fetchProcesses = async() => {
        return await fetch(process.env.REACT_APP_BACKEND_URL + "/mentorships/processes", {
            credentials: "include"
        });
    }

    useEffect(() => {
        fetchProcesses().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setProcesses(data);
                })
            } else {
                toast.error("We failed to fetch mentorship processes");
            }
        }).catch(error => {
            toast.error("We failed to fetch mentorship processes - " + error);
        })
    }, []);


    if (user == null) return;

    const filteredProcesses = processes.filter(process => isStateFiltered(process.status));

    return <div className="container">
         <MultiSelect
            label="Filter by state"
            placeholder="Pick value"
            data={['In Process', 'Completed', 'Archived']}
            className="max-w-[450px]"
            onChange={value => {
                setStates(value);
            }}
            />

            <div className="flex flex-col gap-10 mt-10">
                {filteredProcesses.map(process => {

                    const sessionData = JSON.parse(process.stripePaymentData);

                    return (
                    
                    <div className="border-[white] border-[2px] p-3 rounded-[12px] w-[100%] transition-all" key={process.createdAt}>
                        <div className="flex flex-wrap justify-between">
                            <div className="text-white">
                       
                                <div className="flex gap-2">
                                    <i className="fa-solid fa-sitemap mt-[2px] text-black"></i>
                                    <div>Mentorship: <div className="text-[#a1ff4f] underline inline-block">{process.mentorshipName}</div></div>
                                </div>

                                <div className="flex items-center gap-5 flex-wrap">

                                    <div className="flex gap-2">
                                        <i className="fa-solid fa-user-tie mt-[2px] text-black"></i>
                                        <div>User: <div className="text-[#a1ff4f] underline inline-block">{process.userName}</div></div>
                                    </div>

                                    <div className="flex gap-2">
                                        <i className="fa-solid fa-envelope mt-[4px] text-black"></i>
                                        <div>Email: <div className="text-[#a1ff4f] underline inline-block">{process.email}</div></div>
                                    </div>

                                    <div className="flex gap-2">
                                        <i className="fa-regular fa-circle mt-[5px] text-black"></i>
                                        <div className="text-white">Status: <div className="text-[#a1ff4f] underline inline-block">{process.status}</div></div>
                                    </div>

                                    <div className="flex gap-2">
                                        <i className="fa-brands fa-discord mt-[5px] text-black"></i>
                                        <div className="text-white">Discord ID: <div className="text-[#a1ff4f] underline inline-block">{process.discordId}</div></div>
                                    </div>
                                </div>

                

                                <br />
                                <div className="flex gap-2">
                                    <i className="fa-solid fa-calendar-days mt-[2px] text-black"></i>
                                    <div className="text-white">Created at: <div className="text-[#a1ff4f] underline inline-block">{formatDate(new Date(process.createdAt))}</div></div>
                                </div>

                                <div className="flex gap-2">
                                    <i className="fa-regular fa-calendar-days mt-[2px] text-black"></i>
                                    <div className="text-white">Full payment completed: <div className="text-[#a1ff4f] underline inline-block">{process.fullPaymentCompletedAt != -1 ? formatDate(new Date(process.fullPaymentCompletedAt)) : "No"}</div></div>
                                </div>

                                <div className="flex gap-2">
                                    <i className="fa-brands fa-stripe mt-[2px] text-black"></i>
                                    <div className="text-white">Stripe payment ID: <div className="text-[#a1ff4f] underline inline-block">{process.fullPaymentCompletedAt != -1 ? sessionData.payment_intent : "Unknown"}</div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </div>
    </div>
}

function formatDate(date) {
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };
    return date.toLocaleString('en-US', options);
}

export default ProcessesPage;