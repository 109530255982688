import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import LoginPage from "./login/LoginPage";
import { UserContext } from "./contexts/Contexts";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import AlreadyLoggedPage from "./login/AlreadyLoggedPage";
import DashboardPage from "./dashboard/DashboardPage";
import ProcessesPage from "./dashboard/ProcessesPage";
import Navigation from "./navigation/Navigation";
import { MantineProvider } from "@mantine/core";
import '@mantine/core/styles.css';

const router = createBrowserRouter([
  {
    path: "/",
    element: <ProcessesPage / >
  },
  {
    path: "login",
    element: <LoginPage />,
  },
  {
    path: "logged-in",
    element: <AlreadyLoggedPage />,
  },
  {
    path: "dashboard",
    element: <DashboardPage />,
  },
]);

function App() {

  const [currentUser, setCurrentUser] = useState(null);

  const fetchUser = async () => {
    return await fetch(process.env.REACT_APP_BACKEND_URL + "/auth/user", {
      credentials: "include"
    });
  }

  React.useEffect(() => {
    fetchUser().then(response => {
      if (response.ok) {
        response.json().then(data => {
          setCurrentUser(data);
          toast.success("You're logged in as " + data.email, {position: "bottom-left"});
        });
      } else {
        if (window.location.pathname != "/login") {
          toast.error("Failed to find your account, please login");
          setTimeout(() => {
            window.location.href = "/login";
          }, 1500);
        }

      }
    }).catch(error => {
      if (window.location.pathname != "/login") {
        toast.error(error);

        setTimeout(() => {
          window.location.href = "/login";
        }, 1500);
      }
    })
  }, []);
  return (
     <MantineProvider>
      <UserContext.Provider value={currentUser}>
        <Toaster />
        <Navigation />

        <RouterProvider router={router} />
      </UserContext.Provider>
    </MantineProvider>
  );
}

export default App;
