import { useContext } from "react";
import toast from "react-hot-toast";
import Cookies from "universal-cookie";
import { UserContext } from "../contexts/Contexts";
import { Link } from "react-router-dom";

function Navigation() {

    const cookies = new Cookies();
    const user = useContext(UserContext);

    if (user == null) return;

    return <div className="container">
        <div className="flex justify-end w-full mt-10 gap-5">
        
            <a className="text-[#89c0ff] text-[18px] underline hover:cursor-pointer" href="/">Home</a>
            <a className="text-[#89c0ff] text-[18px] underline hover:cursor-pointer" href="/dashboard">Dashboard</a>         

            <a className="text-[#ff7d7d] text-[18px] underline hover:cursor-pointer" onClick={() => {
                cookies.remove("authorization");

                toast.success("You've been logged out");

                setTimeout(() => {
                    window.location.href = "/";    
                }, 1000);
            }}>Logout</a>
        </div>
    </div>
}

export default Navigation;