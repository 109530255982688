import { useContext, useEffect } from "react";
import { UserContext } from "../contexts/Contexts";
import { Form } from "react-router-dom";
import toast from 'react-hot-toast';
import Cookies from 'universal-cookie';

function LoginPage() {

    const user = useContext(UserContext);
    const cookies = new Cookies(null, { path: '/' });

    useEffect(() => {
        if (user != null) {
            window.location.href = "/logged-in";
        }
    }, [user]);

    if (user != null) return;
    

    return <div className="container flex justify-center">
            <div className="flex flex-col items-center mt-[20%]">
                <h1 className="text-[40px] text-white font-bold">Login</h1>

                <Form className="flex flex-col justify-center items-center gap-3" onSubmit={(submit) => {
                    submit.preventDefault();

                    const formData = new FormData(submit.target);
                    const data = Object.fromEntries(formData);

                    fetch(process.env.REACT_APP_BACKEND_URL + "/auth/login", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(data)
                      }).then(response => {
                        if (response.ok) {
                            response.json().then(data => {
                                const token = data.token;

                                if (token != null) {
                                    toast.success("You have been logged in"); 

                                    cookies.set("authorization", token);

                                    setTimeout(() => {
                                        window.location.href = "/";
                                    }, 500);
                                } else {
                                    toast.error(data.message)
                                }
    
                            })
                        }
                      })

                    console.log(data);
                }}>
                    <input name="email" className="p-3 w-[350px] bg-[#27405d5c] text-white" type="email" placeholder="Email"></input>
                    <input name="password" className="p-3 w-[350px] bg-[#27405d5c] text-white" type="password" placeholder="Password"></input>

                    <input className="text-white bg-[#27405d5c] p-3 rounded-[50px] w-[350px] hover:cursor-pointer" type="submit" value="Login"></input >
                </Form>
            </div>
    </div>
}

export default LoginPage;