import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { UserContext } from "../contexts/Contexts";

function DashboardPage() {

    const [mentorships, setMentorships] = useState([]);
    const user = useContext(UserContext);

    const fetchMentorships = async() => {
        return await fetch(process.env.REACT_APP_BACKEND_URL + "/mentorships", {
            credentials: "include"
        });
    }

    const changeAllowedMenteeCount = async (productId, newCount) => {
        return await fetch(process.env.REACT_APP_BACKEND_URL + "/mentorships/allowed-mentee", {
            credentials: "include",
            method: "PUT",
            body: JSON.stringify({
                productId: productId,
                count: newCount
            })
        });
    
    }

    const handleImageUpload = (mentorship) => {
        // Create a file input dynamically
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/*';

        // Trigger the file input click
        fileInput.click();

        // Listen for file selection
        fileInput.onchange = async (event) => {
            const file = event.target.files[0];
            if (file) {
                const formData = new FormData();
                formData.append('file', file);

                try {
                    toast("Please wait..");

                    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/mentorships/image-update/' + mentorship.productId, {
                        credentials: "include",
                        method: 'POST',
                        body: formData,
                    });

                    if (response.ok) {                        
                        response.json().then(data => {
                            const imageName = data.imageName;

                            setMentorships(mentorships.map(loaded => loaded.productId == mentorship.productId ? {
                                ...loaded,
                                imageName: imageName
                            } : loaded));

                            toast.success("Image has been successfully changed.")
                        });
                    } else {
                        toast.error("Failed to upload an image for mentorship");
                    }
                } catch (error) {
                    toast.error("An error occurred while uploading the image " + error)
                }
            }
        };
    };

    useEffect(() => {
        fetchMentorships().then(response => {
            if (response.ok) {
                response.json().then(data => {
                    setMentorships(data);
                })
            } else {
                toast.error("We failed to fetch mentorships");
            }
        }).catch(error => {
            toast.error("We failed to fetch mentorships - " + error);
        })
    }, []);

    if (user == null) return;

    return <div className="container">
            <div className="flex flex-col gap-10 mt-10">
                {mentorships.map(mentorship => {

                    return (
                    
                    <div className="border-[white] border-[2px] p-3 rounded-[12px] w-[100%] transition-all" key={mentorship.productId}>
                        <div className="flex flex-wrap justify-between">
                            <div className="text-white">
                                <p className="text-[22px] font-bold underline text-[#3c52a2]">{mentorship.name}</p>
                                <p className="max-w-[600px] mt-10 text-black">{mentorship.description}</p>

                                <div className="flex gap-2 mt-10">
                                    <i className="fa-solid fa-user-tie mt-[2px] text-black"></i>
                                    <div>Allowed mentee: <div className="text-[#a1ff4f] underline inline-block">{mentorship.menteeAllowed}</div></div>
                                </div>

                                <div className="flex gap-2">
                                    <i className="fa-solid fa-dice mt-[2px] text-black"></i>
                                    <div>Current enrolled users: <div className="text-[#a1ff4f] underline inline-block">{mentorship.menteeAccepted}/{mentorship.menteeAllowed}</div></div>
                                </div>

                                <div className="flex gap-2">
                                    <i className="fa-solid fa-money-bills mt-[2px] text-black"></i>
                                    <div className="text-white">Full fees: <div className="text-[#a1ff4f] underline inline-block">${mentorship.fullPrice}</div></div>
                                </div>

                                <div className="flex gap-2">
                                    <i className="fa-solid fa-list mt-[2px] text-black"></i>
                                    <div className="text-white">Category: <div className="text-[#a1ff4f] underline inline-block">{mentorship.categoryName}</div></div>
                                </div>

                                <div className="flex gap-2">
                                    <i className="fa-regular fa-circle mt-[2px] text-black"></i>
                                    <div className="text-white">Role: <div className="text-[#a1ff4f] underline inline-block">{mentorship.roleName}</div></div>
                                </div>

                                <div className="buttons mt-10">
                                    <button className= "bg-[#3c52a2] p-3 rounded-[20px] text-white text-[14px] transition-all hover:translate-x-10" onClick={() => {
                                        const input = prompt("Please enter a new count for mentee");

                                        if (isNaN(parseFloat(input)) || !isFinite(input)) {
                                            toast.error("Please use a valid number for the count");
                                            return;
                                        }

                                        changeAllowedMenteeCount(mentorship.productId, input).then(response => {
                                            if (response.ok) {
                                                toast.success("You have updated mentee count to " + input);
                                                setMentorships(mentorships.map(loaded => loaded.productId == mentorship.productId ? {
                                                    ...loaded,
                                                    menteeAllowed: input
                                                } : loaded));
                                            } else {
                                                toast.error("We failed to update mentorship");
                                            }
                                        }).catch(error => {
                                            toast.error("We failed to update mentorship - " + error);
                                        })
                                    }}>Change allowed mentee count</button>
                                </div>
                            </div>

                            <div className="relative hover:cursor-pointer group max-w-[400px] max-h-[150px]" onClick={() => {
                                handleImageUpload(mentorship);
                            }}>
                                <div className="overlay w-full h-full absolute bg-[black] z-2 opacity-80 group-hover:opacity-0 transition-all rounded-[12px]"></div>
                                <img className="w-full h-full min-w-[400px] min-h-[150px] rounded-[12px] object-cover" src={process.env.REACT_APP_BACKEND_URL + "/mentorships/images/" + (mentorship.imageName != null ? mentorship.imageName : "default.png")} alt="" />
                                
                                <button className="text-white group-hover:hidden text-[18px] font-bold z-4 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 underline">Change image</button>
                            </div>
                        </div>
                    </div>
                    )
                })}
            </div>
    </div>
}

export default DashboardPage;