import { useContext, useEffect } from "react";
import { UserContext } from "../contexts/Contexts";
import { Form } from "react-router-dom";
import toast from 'react-hot-toast';
import Cookies from 'universal-cookie';

function AlreadyLoggedPage() {

    const user = useContext(UserContext);
    const cookies = new Cookies(null, { path: '/' });

    return <div className="container flex justify-center">
            <div className="flex flex-col items-center mt-[20%]">
                <h1 className="text-[40px] text-white font-bold">You are already logged in!</h1>
            </div>
    </div>
}

export default AlreadyLoggedPage;